import { LoadingButton } from '@mui/lab';
import { Box, Button, SxProps, styled, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { MouseEvent, useCallback, useMemo } from 'react';
import { AwardDetails, AwardDetailsProps } from '../AwardDetails/AwardDetails';
import { Dialog, DialogCloseReason, DialogProps } from '../Dialog';
import { ReceivedBadge } from '../ReceivedBadge';
import { Can } from '../Can';
import { Action, ActionSubject } from '../../utils/enums/abilities';

export type AwardDetailsDialogViewProps = DialogProps &
	AwardDetailsProps & {
		badgeName: string;
		badgeSrc: string;
		editing?: boolean;
		processing?: boolean;
		approving?: boolean;
		deleting?: boolean;
		onApproveClick?: () => void;
		onDeleteClick?: () => void;
		withCloseBottomButton?: boolean;
	};

export function AwardDetailsDialogView({
	sender,
	manager,
	receiver,
	status,
	message,
	showHistoryDetail,
	sentAt,
	badgeSrc,
	badgeName,
	editing,
	processing,
	approving,
	deleting,
	onClose,
	onApproveClick,
	onDeleteClick,
	withCloseBottomButton,
	...props
}: AwardDetailsDialogViewProps) {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	const contentPropsSx: SxProps = useMemo(() => {
		const result = [
			{
				paddingTop: !props.title ? '88px' : 0,
				paddingRight: editing ? '42px' : '54px',
				'@media (max-width: 900px)': {
					padding: '30px 34px 43px 32px',
				},
			},
		];

		if (props?.ContentProps?.sx) {
			result.push(props.ContentProps.sx as any);
		}

		if (withCloseBottomButton) {
			result.push({
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
			} as any);
		}

		return result.flat();
	}, [editing, props.ContentProps?.sx, props.title, withCloseBottomButton]);
	const handleCloseWindowClick = useCallback(
		(e: MouseEvent<HTMLButtonElement>) => {
			if (onClose) onClose(e, DialogCloseReason.CloseButton);
		},
		[onClose]
	);
	const containerPropsSx: SxProps = useMemo(() => {
		const sx = [{ flex: 1 }];

		if (props.ContainerProps?.sx) {
			sx.push(props.ContainerProps.sx as any);
		}

		if (withCloseBottomButton) {
			sx.push({
				display: 'flex',
				flexDirection: 'column',
			} as any);
		}
		return sx.flat();
	}, [withCloseBottomButton, props.ContainerProps?.sx]);

	return (
		<Dialog
			{...props}
			LeftColumnProps={{
				sx: {
					maxWidth: '276px',
					'@media (max-width: 900px)': {
						maxWidth: 'unset',
						backgroundColor: '#f6f4f4',
						'>div': {
							width: '276px',
							margin: '0 auto',
						},
					},
				},
			}}
			ContainerProps={{
				...props?.ContainerProps,
				sx: containerPropsSx,
			}}
			ContentProps={{
				...props?.ContentProps,
				sx: contentPropsSx,
			}}
			CloseBtnProps={{
				sx: {
					'@media (max-width: 900px)': {
						top: 11,
						right: 6,
					},
				},
			}}
			leftColumn={
				<ReceivedBadge
					sx={{ minHeight: '100%' }}
					badgeSrc={badgeSrc}
					badgeName={badgeName}
				/>
			}
			topBanner={fullScreen}
			onClose={onClose}
		>
			<AwardDetails
				sender={sender}
				message={message}
				sentAt={sentAt}
				status={status}
				manager={manager}
				receiver={receiver}
				showHistoryDetail={showHistoryDetail}
			/>
			{withCloseBottomButton && (
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
					}}
				>
					<ActionButton variant='contained' onClick={handleCloseWindowClick}>
						Close window
					</ActionButton>
				</Box>
			)}
			{editing && (
				<Box
					sx={{
						paddingTop: '48px',
						display: 'flex',
						justifyContent: 'space-between',
					}}
				>
					<ActionButton
						variant='contained'
						color='secondary'
						onClick={handleCloseWindowClick}
					>
						Close window
					</ActionButton>
					<Can do={Action.Update} on={ActionSubject.BadgeAward}>
						<LoadingActionButton
							variant='contained'
							color='primary'
							onClick={onApproveClick}
							disabled={processing}
							loading={approving}
						>
							Approve badge
						</LoadingActionButton>
						<LoadingActionButton
							variant='contained'
							color='error'
							onClick={onDeleteClick}
							disabled={processing}
							loading={deleting}
						>
							Decline request
						</LoadingActionButton>
					</Can>
				</Box>
			)}
		</Dialog>
	);
}

const ActionButton = styled(Button)({
	paddingTop: 7,
	paddingRight: 15,
	paddingLeft: 15,
	paddingBottom: 7,
});

const LoadingActionButton = ActionButton.withComponent(LoadingButton);
